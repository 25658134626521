import React from "react";
import { Helmet } from 'react-helmet';
import { Link, graphql } from "gatsby";
import * as BS from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BreadcrumbLite } from '../components/common/BreadcrumbLite'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import './market.css'
import BreadHome from '../components/BreadHome'

const preSeedVCs = ({data}) => {

  const renderCategory = (edge, index) => {

    if(edge.node.url !== 'none' && edge.node.name !== 'drone'){

      return(
      <BS.Card style={{ marginTop: 10 }}>
          <BS.Card.Header>{edge.node.name[0].toUpperCase() + edge.node.name.substring(1)}</BS.Card.Header>
          <BS.Card.Body>
            <BS.Card.Text>
              Deep dive into the growing healthtech market.
      </BS.Card.Text>
            <Link to={edge.node.url}><BS.Button style={{ background: 'rgb(238 243 249)', border: 'rgb(33 98 175)', color: 'black' }}>Get the report</BS.Button></Link>
          </BS.Card.Body>
        </BS.Card>
      )

    }

  }

  // const renderCategory = (edge, index) => {
    
  //   const category = edge.node.name
  //   const colours = ['#663399', '#BA348D', '#F05274', '#FF865D', '#FFBF54', '#0080EA', '#00C09B', '#333', ]

  //   return(
  //     <div >
  //      <Link to={`/market/${edge.node.name}/`}><BS.Card.Title style={{ marginTop: 20, marginBottom: 7, color: '#000' }}><b>{edge.node.name}</b></BS.Card.Title></Link>
  //           <BS.Card style={{ marginTop: 0, maxWidth: 400, marginRight: 10, border: `2px solid #663399` }}>
  //             <BS.Card.Body style={{padding: 5}}>
  //                 <div className="flexbox-container">
  //                 {props.data.allFunding.edges.map(edge => {
  //                   // for ( let i = 0; i < props.data.allFunding.edges.length; i++){
  //                     if(edge.node.businessRef.what === category && props.data.allFunding.edges.indexOf(edge.node.businessRef.name === -1)){

  //                       const isLogo = () => {
  //                         if (edge.node.businessRef.imageFile !== 'none' && edge.node.businessRef.imgTWhite === false && edge.node.businessRef.what === category) {
  //                           return (
  //                             <img style={{margin: 5, maxWidth: 60}} src={`/images/business/${edge.node.businessRef.imageFile}`} alt={`${edge.node.businessRef.name} logo`} />
  //                           )
  //                         } 
  //                         else if (edge.node.businessRef.imageFile !== 'none' && edge.node.businessRef.imgTWhite === true && edge.node.businessRef.what === category) {
  //                           return (
  //                             <img style={{margin: 5, maxWidth: 70, padding: 10, background: colours[Math.floor(Math.random() * colours.length)]}} src={`/images/business/${edge.node.businessRef.imageFile}`} alt={`${edge.node.name} logo`} />
  //                           )
  //                         } 
  //                       } 

  //                       return (
  //                         <div className="flexbox-item flexbox-item-1">
  //                         < Link to={`/business/${edge.node.businessRef.name.toLowerCase().replace(/ /g, "-")}/`}>{isLogo()}</Link>
  //                         </div>
  //                       )
  //                     } 
  //                   })}
  //                 </div>
  //             </BS.Card.Body>
  //           </BS.Card>
  //    </div>
  //  ) }
  



  return (

    <section>
      <Helmet>
        <title>Trending markets and reports by Unicorn Alert</title>
        <meta name="title" content="Trending markets and reports by Unicorn Alert"></meta>
        <meta name="description" content='Get the latest updates from startups, investors and fundraising in your realtime Unicorn Alert dashboard.' />
      </Helmet>
      <BS.Container style={{ paddingLeft: 0, paddingRight: 11, maxWidth: 1240 }}>
      <BreadcrumbLite>
      <BreadHome/>
        <BS.Breadcrumb.Item> <Link to={`/`}>Data</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item active>Markets</BS.Breadcrumb.Item>
      </BreadcrumbLite>

       <BS.Row >
         <BS.Col style={{display: 'block'}} sm={12}>
          <h1>Trending Markets - Ranked By Number Of Investments</h1>
          <h2>Discover the startups that raised the most money this year!</h2>
         </BS.Col>
       </BS.Row>
       <BS.Row >
         <BS.Col sm={12}>
          <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 600: 2, 800: 3, 1000: 4, 1400: 5, 1500: 6}}><Masonry>
          {data.allCategory.edges.map(renderCategory)}
            </Masonry>
            </ResponsiveMasonry>
          </BS.Col>
        </BS.Row>
      </BS.Container>
    </section >
  );
}


export const query = graphql`
{
  allCategory(sort: {fields: howMany, order: DESC}) {
    edges {
      node {
        name
        amount
        currency
        howMany
        url
      }
    }
  }

}
`

// export const query = graphql`
// {
//   allFunding(limit: 10, sort: {order: DESC, fields: businessRef___totalRaised}) {
//     edges {
//       node {
//         businessRef {
//           id
//           name
//           currency
//           totalRaised
//           what
//           imgTWhite
//           imageFile
//         }
//       }
//     }
//   }

//   allCategory(sort: {fields: howMany, order: DESC}) {
//     edges {
//       node {
//         name
//         amount
//         howMany
//       }
//     }
//   }


// }
// `

export default preSeedVCs
